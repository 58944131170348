//
// Stylesheets
//

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

// SCSS
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/stylesheets/application.scss'

Vue.use(BootstrapVue)
