import Vue from 'vue'

// Extras
import router from './router'
import store from './store'
import './scripts'
import './styles'

// Components
import AppComponent from './components/App.vue'

// Disable debug messages when in production mode.
Vue.config.productionTip = false

new Vue({
  render: h => h(AppComponent),
  router: router,
  store: store
}).$mount('#app')
